
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Details_of_the_Object_Model
// https://tylermcginnis.com/beginners-guide-to-javascript-prototype/
/*
var story = {
    id: "",
    title: ""
};
*/

export function Book(id, title, url='') {
  let book = {}
  book.id = id;
  book.title = title;
  book.url = url;

  return book
}