import React, { Component } from 'react';

import { getQuotes } from '../../services/app/api/quotes';

import Quotes from '../../components/quotes/quotes';
import './quotes.css';

class QuotesContainer extends Component {

    state = {
        quotes: [],
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log(`[${this.constructor.name}.js] componentWillMount`);
    }

    componentDidMount() {
        console.log(`[${this.constructor.name}.js] componentDidMount`);
        /* 
         * setState reloads DOM
         * it's called asynchronously so this.state may not always refer to the most updated version of state
         * at the time the handler executes (this could happen if setState was called at the same time in 
         * two different places in the code and mutated the same object). To handle this issue, we can 
         * use a version of setState that takes a function as a parameter instead of an object.
         */
        getQuotes((success, response) => {
            if(success) {
                this.setState({quotes: response.data.quotes});
            } else {
                alert(response);
            }
        });
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log(`[${this.constructor.name}.js] componentWillUnmount`);
    }

    componentWillReceiveProps(nextProps) {
        console.log(`[${this.constructor.name}.js] componentWillReceiveProps`);
    }

    componentWillUpdate(nextProps, nextState) {
        console.log(`[${this.constructor.name}.js] componentWillUpdate`)
    }

    componentDidUpdate() {
        console.log(`[${this.constructor.name}.js] componentDidUpdate`)
    }

    render() {
        return (
            <div className='quotes'>
                <h2>Quotes I like</h2>
                <ul>
                    <Quotes
                        quotes={this.state.quotes}
                    />
                </ul>
            </div>
        )
    }

}

export default QuotesContainer;
