import React, { Component } from 'react';

import { getStories } from '../../services/app/api/stories';

import StoryList from '../../components/stories/story_list';
import './stories.css';

class StoriesContainer extends Component {

    state = {
        stories: [],
        /*
        stories: [
            {id: "1", title: "things fall apart"}, 
            {id: "2", title: "no longer at ease"}
        ],
        */
        //loading: true
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log(`[${this.constructor.name}.js] componentWillMount`);
    }

    componentDidMount() {
        console.log(`[${this.constructor.name}.js] componentDidMount`);
        /* 
         * setState reloads DOM
         * it's called asynchronously so this.state may not always refer to the most updated version of state
         * at the time the handler executes (this could happen if setState was called at the same time in 
         * two different places in the code and mutated the same object). To handle this issue, we can 
         * use a version of setState that takes a function as a parameter instead of an object.
         */
        getStories((success, response) => {
            if(success) {
                this.setState({stories: response.data.stories});
            } else {
                alert(response);
            }
        });
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log(`[${this.constructor.name}.js] componentWillUnmount`);
    }

    componentWillReceiveProps(nextProps) {
        console.log(`[${this.constructor.name}.js] componentWillReceiveProps`);
    }

    /*
     * this can be used to improve performance
     * shouldComponentUpdate(nextProps, nextState) {
     * console.log("[Persons.js] componentShouldUpdate", nextProps, nextState)
     *     // return false is you don't want the application to update
     *     return nextProps.persons !== this.props.persons || 
     *         nextProps.changed !== this.props.changed ||
     *         nextProps.clicked !== this.props.clicked;
     * }
     */
    componentWillUpdate(nextProps, nextState) {
        console.log(`[${this.constructor.name}.js] componentWillUpdate`, nextProps, nextState);
    }

    componentDidUpdate() {
        console.log(`[${this.constructor.name}.js] componentDidUpdate`);
    }

    // react doesn't autobind functions to class: https://stackoverflow.com/questions/32732989/getting-typeerror-this-props-is-undefined-on-reactjs
    // this for the class or this for the dom: https://medium.com/komenco/react-autobinding-2261a1092849
    onClickStory = (storyId) => {
        this.props.history.push('/stories/' + storyId)
    }

    render() {
        return (
            <div className='stories'>
                <h2>Things I've Written</h2>
                <ul>
                <StoryList
                    stories={this.state.stories}
                    storyClicked={this.onClickStory}
                />
                </ul>
            </div>
        )
    }

}

export default StoriesContainer;

