import Chart from '../models/charts';

var _charts = [];

function getChartData(chart) {
    console.log(chart.chart_name)
    switch(chart.chart_name) {
        case 'Current Twitter Trends':
            return chart.data[0].trends
        case 'Crypto Prices':
            return chart.crypto
        default:
          // code block
      }
}
 
let chartStore = {
    getChart: function(id) {
        //return stories.concat(); // concat creates a copy of the array so modules can modify their copy without affecting the original 
        return _charts.find(x => x.id === id);
    },

    getCharts: function() {
        //return stories.concat(); // concat creates a copy of the array so modules can modify their copy without affecting the original 
        return _charts;
    },

    setChart: function(chart) {
        const chartData = getChartData(chart);
        _charts = Chart(1, chart.chart_name, chartData);
        return _charts
    }
};

export default chartStore;