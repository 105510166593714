import React from 'react';

import './book.css';

const book = (props) => {
    return (
        <li className='book-list'>
            <a href={props.url} target='_blank' rel='noopener noreferrer' className='book'>{props.title}</a>
        </li>
    )
}

export default book;