import axios from 'axios';

import { GENERIC_ERROR } from '../../../resources/strings/errorHandling';


let baseUrl = process.env.REACT_APP_BASE_API_URL;

export function getListenings(args) {
    let url = baseUrl + '/music/my-music'
    console.log(`Get my-music from url: ${url}`)
    
    axios.get(url).then(response => { // then gets executed once the promise resolves
        if(response.status === 200){
            args[0](true, response)
        } else {
            args[0](false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        console.log(error)
        args[0](false, GENERIC_ERROR)
    });
}