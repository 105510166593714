import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import NavLinks from '../../components/toolbar/navLinks/navLinks';
import './menu.css';

class MenuContainer extends Component {

    constructor(props) {
        super(props);
        console.log("[menu_container.js] constructor", props);
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log("[menu_container.js] componentWillMount");
    }

    componentDidMount() {
        console.log("[menu_container.js] componentDidMount");
    }

    componentWillUnmount() { // // un-mounting: clean up component activities when component is removed from the DOM
        console.log("[menu_container.js] componentWillUnmount");
    }

    componentWillReceiveProps(nextProps) {
        console.log("[menu_container.js] componentWillReceiveProps");
    }

    componentWillUpdate(nextProps, nextState) {
        console.log("[menu_container.js] componentWillUpdate", nextProps, nextState)
    }

    componentDidUpdate() {
        console.log("[menu_container.js] componentDidUpdate")
    }

    render() {
        return (
            <div className="top-nav">
                <Link to={"/"} style={{ textDecoration: 'none', color: 'black'}}><h1 className="logo">def abstractFactory:</h1></Link>
                <NavLinks topNavLinks="menu-links" topNavLink="menu-link"/>
            </div>
        )
    }

}

export default MenuContainer;
