import axios from 'axios';

import { GENERIC_ERROR } from '../../../resources/strings/errorHandling';

export function getProjects(onResponseHandler) {
    var baseUrl = process.env.REACT_APP_BASE_API_URL;

    // promises are a Javascript ES6 feature
    axios.get(baseUrl + '/projects').then(response => { // then gets executed once the promise resolves
        if(response.status === 200){
            /*
            var projects = []
            let p = response.data.projects;
            for (var i = 0; i < p.length; i++) {
                let project = Project(p[i].id, p[i].name, p[i].description);
                projects.push(project);
            }
            */
            onResponseHandler(true, response)
        } else {
            onResponseHandler(false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        onResponseHandler(false, GENERIC_ERROR)
    });
}