import React from 'react';

import './image_profile.css';
import profileImage from '../../resources/images/profile.jpg'


function image_profile(props) {
    return (
        <img src={profileImage} className='image_profile' alt='profileImage'/>
    )
}

export default image_profile;