import React from 'react';

import Story from './story/story';

// it's recommended not to use indices as keys: https://reactjs.org/docs/lists-and-keys.html#keys
function storyList(props) {
    return props.stories.map((story, index) => {
        /*
         * We use the ES6 syntax below so that ingredientAdded doesn't execute immediately
         * the anonymous arrow functions only get executed when the button is clicked
         */
        return (
            <React.Fragment>
                <Story
                    key={story.id}
                    title={story.title}
                    click={() => props.storyClicked(story.id)}
                />
                <br/>
            </React.Fragment>
        )
    })
}

export default storyList;