import React from 'react';

import './listenings.css';

const currentlyPlaying = (props) => {
    if(props.content.length > 0) {
        return (props.content.map(obj => {
            return (
                <a className='link-style' href={obj.link} target='_blank' rel='noopener noreferrer'>
                    <div className='box-style'>
                        {obj.artist}: {obj.track}
                    </div>
                </a>
            )
        }))
    } else {
        return (
            <div className='box-style'>
                Not a thang
            </div>
        )
    }
}

export default currentlyPlaying;