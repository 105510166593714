import axios from 'axios';

import { Story } from '../../../models/stories';
import { GENERIC_ERROR } from '../../../resources/strings/errorHandling';

// https://stackoverflow.com/questions/31354559/using-node-js-require-vs-es6-import-export
var StoryStore = require('../../../data_store/story_store');

export function getStories(onResponseHandler) {
    var baseUrl = process.env.REACT_APP_BASE_API_URL;

    // promises are a Javascript ES6 feature
    axios.get(baseUrl + '/stories').then(response => { // then gets executed once the promise resolves
        if(response.status === 200){
            var stories = []
            let s = response.data.stories;
            for (var i = 0; i < s.length; i++) {
                let story = Story(s[i].id, s[i].title);
                stories.push(story);
            }
            StoryStore.setStories(stories)
            onResponseHandler(true, response)
        } else {
            onResponseHandler(false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        onResponseHandler(false, GENERIC_ERROR)
    });
}

export function getStory(storyId, onResponseHandler) {
    var baseUrl = process.env.REACT_APP_BASE_API_URL;
    // promises are a Javascript ES6 feature
    axios.get(baseUrl + '/stories/' + storyId).then(response => { // then gets executed once the promise resolves
        if(response.status === 200){
            onResponseHandler(true, response)
        } else {
            onResponseHandler(false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        onResponseHandler(false, GENERIC_ERROR)
    });
}