import React from 'react';

import './quote.css';

const quote = (props) => {
    return (
        <li className='quote-item'>
            <p>
                <div className='quote'>
                    <div className='quote-text'>{props.text}</div> - <div className='quote-author'>{props.author}</div>
                </div>
            </p>
        </li>
    )
}

export default quote;