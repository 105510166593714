import React, { Component } from 'react';

import { getBooks } from '../../services/app/api/books';

import Books from '../../components/books/books';
import './books.css';

class BooksContainer extends Component {

    state = {
        books: [],
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log(`[${this.constructor.name}.js] componentWillMount`);
    }

    componentDidMount() {
        console.log(`[${this.constructor.name}.js] componentDidMount`);
        /* 
         * setState reloads DOM
         * it's called asynchronously so this.state may not always refer to the most updated version of state
         * at the time the handler executes (this could happen if setState was called at the same time in 
         * two different places in the code and mutated the same object). To handle this issue, we can 
         * use a version of setState that takes a function as a parameter instead of an object.
         */
        getBooks((success, response) => {
            if(success) {
                this.setState({books: response.data.books});
            } else {
                alert(response);
            }
        });
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log(`[${this.constructor.name}.js] componentWillUnmount`);
    }

    componentWillReceiveProps(nextProps) {
        console.log(`[${this.constructor.name}.js] componentWillReceiveProps`);
    }

    /*
     * this can be used to improve performance
     * shouldComponentUpdate(nextProps, nextState) {
     * console.log("[Persons.js] componentShouldUpdate", nextProps, nextState)
     *     // return false is you don't want the application to update
     *     return nextProps.persons !== this.props.persons || 
     *         nextProps.changed !== this.props.changed ||
     *         nextProps.clicked !== this.props.clicked;
     * }
     */
    componentWillUpdate(nextProps, nextState) {
        console.log(`[${this.constructor.name}.js] componentWillUpdate`)
    }

    componentDidUpdate() {
        console.log(`[${this.constructor.name}.js] componentDidUpdate`)
    }

    render() {
        return (
            <div className='books'>
                <h2>Things I've Read</h2>
                <ul>
                    <Books
                        books={this.state.books}
                    />
                </ul>
            </div>
        )
    }

}

export default BooksContainer;
