import React, { Component } from 'react';

import ImageProfile from '../../components/image_profile/image_profile';
import NavLinks from '../../components/toolbar/navLinks/navLinks';
import RotatingBrackets from '../../components/rotating_brackets/rotating_brackets';

import './home.css';
/*
 * using the image path in code won't work so we must import it
 * webpack makes its own assets folder when it bundles the project so we need to tell it that 
 * we're using this image - this will tell webpack to copy this image to its own assets folder
 */

//import mainImage from '../../assets/images/mapbox_home.png'
// <img src={mainImage} alt='Main' className="mainImage"/>

class HomeContainer extends Component {

    constructor(props) {
        super(props);
        console.log("[home.js] constructor", props);
    }

    render() {
        return (
            <div>
                <div className="home-profile-image">
                    <ImageProfile/>
                </div>
                <div className="nav-links-wrapper">
                    <NavLinks navLinks="nav-links" navLink="nav-link"/>
                </div>
                <div className="end-link">
                    <a href="http://hmpg.net" target="_blank">the end</a>
                </div>
            </div>
        )
    }

}

export default HomeContainer;