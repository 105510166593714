import React from 'react';
import { useHistory } from "react-router";

import DehazeIcon from '@material-ui/icons/Dehaze';
import './hamburgerMenu.css';

const HamburgerMenu = (props) => {
    const history = useHistory();

    let onClickMenu = () => {
        history.push({
        pathname:  "/menu",
        /*state: {
          response: messageFromServer 
        }*/
     })
    }

    return (
        <div className={props.className}>
            <DehazeIcon onClick={onClickMenu}/>
        </div>
    )
}

export default HamburgerMenu;