import React, { Component } from 'react';

import StoryFull from '../../../components/stories/story/story_full';
import { getStory } from '../../../services/app/api/stories';

import './story.css';

// https://stackoverflow.com/questions/31354559/using-node-js-require-vs-es6-import-export
//var StoryStore = require('../../../data_store/story_store');

class StoryContainer extends Component {

    state = {
        story: {},
    }

    constructor(props) {
        super(props);
        console.log(`[${this.constructor.name}.js] constructor`, props);
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log(`[${this.constructor.name}.js] componentWillMount`);
    }

    componentDidMount() {
        console.log(`[${this.constructor.name}.js] componentDidMount`);
        let storyId = this.props.match.params.id
        // only set story state if empty (state may not be empty if user refreshes)
        if(Object.keys(this.state.story).length === 0 && this.state.story.constructor === Object) {
            getStory(storyId, (success, response) => {
                if(success) {
                    let s = response.data.story
                    let t = response.data.story_text
                    //let story = StoryStore.getStory(s.id)

                    this.setState({story: {'id': s.id, 'title': s.title, 'text': t.text}}); 
                } else {
                    alert(response);
                }
            });
        }
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log(`[${this.constructor.name}.js] componentWillUnmount`);
    }

    componentWillReceiveProps(nextProps) {
        console.log(`[${this.constructor.name}.js] componentWillReceiveProps`);
    }

    componentWillUpdate(nextProps, nextState) {
        console.log(`[${this.constructor.name}.js] componentWillUpdate`, nextProps, nextState);
    }

    componentDidUpdate() {
        console.log(`[${this.constructor.name}.js] componentDidUpdate`);
    }

    render() {
        return (
            <div>
                <StoryFull 
                    title={this.state.story.title} 
                    text={this.state.story.text}/>
            </div>
        )
    }

}

export default StoryContainer;