import React, { Component } from 'react';
//import apiManager from '../../services/app/api/requests';

//import Projects from '../../components/projects/pProjects';
import './login.css';

class LoginContainer extends Component {

    constructor(props) {
        super(props);
        console.log("[login_container.js] constructor", props);
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log("[login_container.js] componentWillMount");
    }

    componentDidMount() {
        console.log("[login_container.js] componentDidMount");
        //apiManager.execute('getProjects', this.onResponseHandler)
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log("[login_container.js] componentWillUnmount");
    }

    componentWillReceiveProps(nextProps) {
        console.log("[login_container.js] componentWillReceiveProps");
    }

    componentWillUpdate(nextProps, nextState) {
        console.log("[login_container.js] componentWillUpdate", nextProps, nextState)
    }

    componentDidUpdate() {
        console.log("[login_container.js] componentDidUpdate")
    }

    getUrl() {
        let baseUrl = 'https://accounts.spotify.com/authorize';
        let scopes = 'user-read-private user-read-email user-read-recently-played user-top-read \
        user-read-currently-playing user-read-playback-state user-library-read playlist-read-private';
        let redirectUri = 'http://127.0.0.1:5000/music/music-auth';

        return baseUrl + '?response_type=code' + 
            '&client_id=' + process.env.REACT_APP_SPOTIFY_CLIENT_ID +
            (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
            '&redirect_uri=' + encodeURIComponent(redirectUri)
    }

    render() {
        return (
            <div>
                <h2 className='center-text projects-header'>Login</h2>
                <div>
                    <div id="login">
                        <h1>First, log in to spotify</h1>
                            <a href={this.getUrl()}>Log in</a>
                        </div>
                    </div>
            </div>
        )
    }

}

export default LoginContainer;