//var EventEmitter = require('events').EventEmitter;

//var emitter = new EventEmitter();

//var _stories = [];
var _stories = [];
 
// https://codeutopia.net/blog/2016/02/01/react-application-data-flow-where-and-how-to-store-your-data/
// https://stackoverflow.com/questions/16383795/difference-between-module-exports-and-exports-in-the-commonjs-module-system
module.exports = {
  getStory: function(id) {
    //return stories.concat(); // concat creates a copy of the array so modules can modify their copy without affecting the original 
    return _stories.find(x => x.id === id);
  },
 
  /*
  subscribe: function(callback) {
    emitter.addListener('update', callback);
  },
 
  unsubscribe: function(callback) {
    emitter.removeListener('update', callback);
  },
  */
 
  setStories: function(stories) {
    stories.forEach(function(story) {
        _stories.push(story);
    });
    //emitter.emit('update');
  }
};