import axios from 'axios';

import chartStore from '../../../data_store/chart_store';
import { GENERIC_ERROR } from '../../../resources/strings/errorHandling';


export function getChartData(args) {
    var baseUrl = process.env.REACT_APP_BASE_API_URL;

    let chartName = args[0]
    let onResponseHandler = args[1]
    // promises are a Javascript ES6 features
    axios.get(baseUrl + `/data/${chartName}`).then(response => { // then gets executed once the promise resolves
        if(response.status === 200) {
            chartStore.setChart(response.data)
            const charts = chartStore.getCharts()

            onResponseHandler(true, charts)
        } else {
            onResponseHandler(false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        onResponseHandler(false, GENERIC_ERROR)
    });
}