import React from 'react';

import './columns.css';

const columnBox = (props) => {
    return (
        <div className={props.column}>
            <h3>{props.name}</h3>
            {props.children}
        </div>
    )
}

export default columnBox;