import React from 'react';

import './story.css';

const story = (props) => {
    return (
        <li className='story' onClick={props.click}>
            {props.title}
        </li>
    )
}

export default story;