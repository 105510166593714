import React from 'react';

import Image from './image/image';
import './images.css';

function images(props) {
    return props.images.map((image, index) => {
        return (
            <Image
                key={index}
                url={image.url}
            /> 
        )
    })
}

export default images;