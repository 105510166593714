import React from 'react';

import Project from './project/project';
import './projects.css';

function projects(props) {
    return props.projects.map((project, index) => {
        return (
            <div>
                <Project
                    key={index}
                    name={project.name}
                    description={project.description}
                    website={project.website}
                    image={project.image}
                /> 
            </div>
        )
    })
}

export default projects;