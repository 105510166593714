import axios from 'axios';

import { Book } from '../../../models/books';
import { GENERIC_ERROR } from '../../../resources/strings/errorHandling';


// https://stackoverflow.com/questions/31354559/using-node-js-require-vs-es6-import-export
let baseUrl = process.env.REACT_APP_BASE_API_URL;
let BookStore = require('../../../data_store/book_store');

export function getBooks(onResponseHandler) {
    let url = baseUrl + '/books'
    axios.get(url).then(response => { // then gets executed once the promise resolves
        if(response.status === 200){
            var books = []
            let b = response.data.books;
            for (var i = 0; i < b.length; i++) {
                let book = Book(b[i].id, b[i].title, b[i].url);
                books.push(book);
            }
            BookStore.setBooks(books)
            onResponseHandler(true, response)
        } else {
            console.log(response)
            onResponseHandler(false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        console.log(error)
        onResponseHandler(false, GENERIC_ERROR)
    });
}
