import React from 'react';

import Aux from '../../../hoc/aux';

const homeLayout = (props) => (
    <Aux>
        <main>
            {props.children}
        </main>
    </Aux>
);

export default homeLayout;