import axios from 'axios';

import { Quote } from '../../../models/quotes';
import { GENERIC_ERROR } from '../../../resources/strings/errorHandling';


// https://stackoverflow.com/questions/31354559/using-node-js-require-vs-es6-import-export
let baseUrl = process.env.REACT_APP_BASE_API_URL;
let QuoteStore = require('../../../data_store/quote_store');

export function getQuotes(onResponseHandler) {
    let url = baseUrl + '/quotes'
    axios.get(url).then(response => { // then gets executed once the promise resolves
        if(response.status === 200){
            var quotes = []
            let q = response.data.quotes;
            for (var i = 0; i < q.length; i++) {
                let quote = Quote(q[i].id, q[i].author, q[i].text);
                quotes.push(quote);
            }
            QuoteStore.setQuotes(quotes)
            onResponseHandler(true, response)
        } else {
            console.log(response)
            onResponseHandler(false, response)
        }
    }).catch(function(error) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         * src: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
         */
        console.log(error)
        onResponseHandler(false, GENERIC_ERROR)
    });
}
