import { getChartData } from './chartData';
import { getImages } from './images';
import { getListenings } from './listenings';
import { getProjects } from './projects';

/*
 * function needs data from api
 * function sends request to manager including endpoint name and args
 * manager checks if endpoint name is in invoker
 */


// central api for api requests
// command pattern: https://www.toptal.com/javascript/comprehensive-guide-javascript-design-patterns
var invoker = {
    'getChartData': getChartData,
    'getImages': getImages,
    'getListenings': getListenings,
    'getProjects': getProjects,
    subtract: function(x, y) {
        return x - y;
    }
}

function getURL(baseUrl, resource, userId) {
    let url = [];
        url.push(
            baseUrl,
            "/",
            resource,
            "/",
            userId
        );
    return url.join("");
}

var apiManager = {
    execute: function(name, ...args) {
        if(name in invoker) {
            return invoker[name](args)
        }
        return false;
    }
}

export default apiManager;