import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import BooksContainer from './books/books';
import HomeContainer from './home/home';
import ImagesContainer from './images/images';
import ListeningsContainer from './listenings/listenings';
import LoginContainer from './login/login';
import MenuContainer from './menu/menu';
import ProjectsContainer from './projects/projects';
import StoriesContainer from './stories/stories';
import StoryContainer from './stories/story/story';
import QuotesContainer from './quotes/quotes';
import HomeLayout from '../components/layouts/home_layout/home_layout';
import ToolbarLayout from '../components/layouts/toolbar_layout/toolbar_layout';

import './App.css';

function App() {
  /* 
   * Route returns null if the path it's passed doesn't match the path parameter
   * switch only returns the route that matches the request
   * need to configure nested routing at some point: https://tylermcginnis.com/react-router-nested-routes/
   */
  return (
      <Router>
        <Switch>
          <RouteWrapper path="/menu" exact component={MenuContainer} layout={HomeLayout}/>
          <RouteWrapper path="/" exact component={HomeContainer} layout={HomeLayout}/>
          <RouteWrapper path="/images" exact component={ImagesContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/listenings" exact component={ListeningsContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/login" exact component={LoginContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/projects" exact component={ProjectsContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/quotes" exact component={QuotesContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/readings" exact component={BooksContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/stories" exact component={StoriesContainer} layout={ToolbarLayout}/>
          <RouteWrapper path="/stories/:id" exact component={StoryContainer} layout={ToolbarLayout}/>
        </Switch>
      </Router>
  );
}

function RouteWrapper({
  component: Component, 
  layout: Layout, 
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}

export default App;
