import React, { Component } from 'react';

import apiManager from '../../services/app/api/requests';

import Images from '../../components/images/images';
import './images.css';

class ImagesContainer extends Component {

    state = {
        images: [{url:''}, {url:''}, {url:''}],
    }

    constructor(props) {
        super(props);
        console.log("[images_container.js] constructor", props);
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log("[images_container.js] componentWillMount");
    }

    // react doesn't autobind functions to class: https://stackoverflow.com/questions/32732989/getting-typeerror-this-props-is-undefined-on-reactjs
    // this for the class or this for the dom: https://medium.com/komenco/react-autobinding-2261a1092849
    onResponseHandler = (success, response) => {
        if(success) {
            console.log(response.data)

            this.setState({images: response.data.images}); 
        } else {
            alert(response);
        }
    }

    componentDidMount() {
        console.log("[images_containerjs] componentDidMount");
        apiManager.execute('getImages', this.onResponseHandler)
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log("[images_container.js] componentWillUnmount");
    }

    componentWillReceiveProps(nextProps) {
        console.log("[images_container.js] componentWillReceiveProps");
    }

    componentWillUpdate(nextProps, nextState) {
        console.log("[images_container.js] componentWillUpdate", nextProps, nextState)
    }

    componentDidUpdate() {
        console.log("[images_container.js] componentDidUpdate")
    }

    render() {
        return (
            <div>
                <div className="center-text">
                    <h2>Moments I like</h2>
                    <Images
                        images={this.state.images}
                    />
                </div>
            </div>
        )
    }

}

export default ImagesContainer;
