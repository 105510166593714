import React from 'react';

import './story_full.css';

// lookup how innerHTML can go bad
const storyFull = (props) => {
    return (
        <div className="story-full">
            <h3>{props.title}</h3>
            <div dangerouslySetInnerHTML={{__html: props.text}}/>
        </div>
    )
}

export default storyFull;