import React from 'react';

import Toolbar from '../../toolbar/toolbar';

import Aux from '../../../hoc/aux';

const toolbarLayout = (props) => (
    <Aux>
        <Toolbar/>
            <main>
                {props.children}
            </main>
    </Aux>
);

export default toolbarLayout;