
var _books = [];
 
// https://codeutopia.net/blog/2016/02/01/react-application-data-flow-where-and-how-to-store-your-data/
// https://stackoverflow.com/questions/16383795/difference-between-module-exports-and-exports-in-the-commonjs-module-system
module.exports = { 
  setBooks: function(books) {
    books.forEach(function(book) {
        _books.push(book);
    });
  }
};
