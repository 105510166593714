import React from 'react';

import Book from './book/book';

// it's recommended not to use indices as keys: https://reactjs.org/docs/lists-and-keys.html#keys
function books(props) {
    return props.books.map((book, index) => {
        /*
         * We use the ES6 syntax below so that ingredientAdded doesn't execute immediately
         * the anonymous arrow functions only get executed when the button is clicked
         */
        return (
            <React.Fragment>
                <Book
                    key={book.id}
                    title={book.title}
                    url={book.url}
                />
                <br/>
            </React.Fragment>
        )
    })
}

export default books;
