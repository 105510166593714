import React from 'react';

import Quote from './quote/quote';


function quotes(props) {
    return props.quotes.map((quote, index) => {
        return (
            <React.Fragment>
                <Quote
                    key={quote.id}
                    author={quote.author}
                    text={quote.text}
                />
            </React.Fragment>
        )
    })
}

export default quotes;