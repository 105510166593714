
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Details_of_the_Object_Model
// https://tylermcginnis.com/beginners-guide-to-javascript-prototype/
/*
var story = {
    id: "",
    title: ""
};
*/

export function Story(id, title, text='') {
  let story = {}
  story.id = id;
  story.title = title;
  story.text = text;

  return story
}