import React from 'react';

import './columns.css';

// alternate method: https://medium.com/@Carmichaelize/dynamic-tag-names-in-react-and-jsx-17e366a684e9
// https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
const columnList = (props) => {
    return (
        <div className={props.column}>
            <h3 className='column-header'>{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</h3>
            <div className='list-position'>
                {props.children}
            </div>
        </div>
    )
}

/*
{props.content.map(obj => {
    return <li className='list-style'>{obj.artists}</li>
})}
*/

export default columnList;