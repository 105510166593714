import React from 'react';
import { Link } from 'react-router-dom';

import Aux from '../../hoc/aux';
import HamburgerMenu from './hamburgerMenu/hamburgerMenu';
import NavLinks from './navLinks/navLinks';

import './toolbar.css'

const toolbar = (props) => (
    <Aux>
        <div className="top-nav">
            <HamburgerMenu className="hamburger-menu"/>
            <Link to={"/"} style={{ textDecoration: 'none', color: 'black' }}>home</Link>
            <NavLinks navLinks="top-nav-links" navLink="top-nav-link"/>
        </div>
    </Aux>
);

export default toolbar;