import React from 'react';

import './listenings.css';

const tracks = (props) => {
    return (
        props.content.map(obj => {
            return (
                <a className='link-style' href={obj.link} target='_blank' rel='noopener noreferrer'>
                    <li className='list-style'>{obj.artist}: {obj.tracks}</li>
                </a>
            )})
    )
}

export default tracks;