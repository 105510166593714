import React from 'react';

import './project.css';

const project = (props) => {
    return (
        <div className='column'>
            <div className='column-content'>
                <a href={props.website} target='_blank' rel='noopener noreferrer'>
                    <img className='image' src={require('../../../resources/images/' + props.image)} alt={props.image}/>
                </a>
                <h3 className='center-text'>{props.name}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default project;