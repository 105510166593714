import React from 'react';

import './image.css';

const image = (props) => {
    return (
        <img src={props.url} className='images' alt={props.url}/>
    )
}

export default image;