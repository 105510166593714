
export function Quote(id, author, text) {
    let quote = {}
    
    quote.id = id;
    quote.author = author;
    quote.text = text;
  
    return quote
}
