import React from 'react';
import { Link } from 'react-router-dom';

import './navLinks.css'

function navLinks(props) {
    return (
        <div className={props.navLinks}>
            <Link to={"/stories"} style={{ textDecoration: 'none', color: 'black'}}><div className={props.navLink}>writings</div></Link>
            <Link to={"/readings"} style={{ textDecoration: 'none', color: 'black'}}><div className={props.navLink}>readings</div></Link>
            <Link to={"/quotes"} style={{ textDecoration: 'none', color: 'black'}}><div className={props.navLink}>quotes</div></Link>
            <Link to={"/listenings"} style={{ textDecoration: 'none', color: 'black'}}><div className={props.navLink}>listenings</div></Link>
            <Link to={"/images"} style={{ textDecoration: 'none', color: 'black'}}><div className={props.navLink}>moments</div></Link>
        </div>
    )
}

export default navLinks;