import React, { Component } from 'react';

import ColumnList from '../../components/columns/column_list';
import ColumnBox from '../../components/columns/column_box';
import Artists from '../../components/listenings/artists'
import Tracks from '../../components/listenings/tracks'
import CurrentlyPlaying from '../../components/listenings/currently_playing'
import apiManager from '../../services/app/api/requests';

import './listenings.css';

class ListeningsContainer extends Component {

    state = {
        artists: [],
        tracks: [],
        currently_playing: []
    }

    constructor(props) {
        super(props);
        console.log("[listenings_container.js] constructor", props);
    }

    // mounting: render a component to the DOM for the first time
    componentWillMount() {
        console.log("[listenings_container.js] componentWillMount");
    }

    // react doesn't autobind functions to class: https://stackoverflow.com/questions/32732989/getting-typeerror-this-props-is-undefined-on-reactjs
    // this for the class or this for the dom: https://medium.com/komenco/react-autobinding-2261a1092849
    onResponseHandler = (success, response) => {
        if(success) {
            this.setState({artists: response.data.artists, tracks: response.data.tracks, 
                currently_playing: response.data.currently_playing}); 
        } else {
            alert(response);
        }
    }

    componentDidMount() {
        console.log("[listenings_containerjs] componentDidMount");
        apiManager.execute('getListenings', this.onResponseHandler)
    }

    // un-mounting: clean up component activities when component is removed from the DOM
    componentWillUnmount() {
        console.log("[listenings_container.js] componentWillUnmount");
    }

    componentWillReceiveProps(nextProps) {
        console.log("[listenings_container.js] componentWillReceiveProps");
    }

    componentWillUpdate(nextProps, nextState) {
        console.log("[listenings_container.js] componentWillUpdate", nextProps, nextState)
    }

    componentDidUpdate() {
        console.log("[listenings_container.js] componentDidUpdate")
    }

    render() {
        return (
            <div>
                <div className="center-text">
                    <h2>Things I've been Listening to</h2>
                </div>
                <div>
                    <ColumnList
                        name='artists'
                        column='left-column'>
                        <Artists
                            content={this.state.artists}/>
                    </ColumnList>
                    <ColumnBox
                        name='Currently Playing'
                        column='center-column'>
                        <CurrentlyPlaying
                            content={this.state.currently_playing}/>
                    </ColumnBox>
                    <ColumnList
                        name='tracks'
                        column='right-column'>
                        <Tracks
                            content={this.state.tracks}/>
                    </ColumnList>
                </div>
                <div className="listenings-footer">
                    <a href="https://developer.spotify.com/documentation/web-api" target='_blank' rel='noopener noreferrer'>
                        <h6>Source: The Spotify API</h6>
                    </a>
                </div>
            </div>
        )
    }

}

export default ListeningsContainer;
