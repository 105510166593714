import React from 'react';

import './listenings.css';

const artists = (props) => {
    return (
        props.content.map(obj => {
            return <div>
                        <a className='link-style' href={obj.link} target='_blank' rel='noopener noreferrer'>
                            <li className='list-style'>{obj.artists}</li>
                        </a>
                   </div>
        })
    )
}

export default artists;